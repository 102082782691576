/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssetType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;
export type AssetType = typeof AssetType[keyof typeof AssetType];


export function AssetTypeFromJSON(json: any): AssetType {
    return AssetTypeFromJSONTyped(json, false);
}

export function AssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetType {
    return json as AssetType;
}

export function AssetTypeToJSON(value?: AssetType | null): any {
    return value as any;
}

