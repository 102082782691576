import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { Auth0ProviderWithNavigate } from './shared/Auth0ProviderWithNavigate'
import { ApiProvider } from './shared/utils/ApiContext'
import { initializeTracking } from './initializeTracking'

const BUILD_VERSION = `${import.meta.env.VITE_BUILD_VERSION}` //coming from the .env.local file. CI overwrites that.
console.log('Build version: ', BUILD_VERSION)

initializeTracking()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <ApiProvider>
          <App />
        </ApiProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
)
