/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetLivestreamInfoResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetLivestreamInfoResponseFromJSON,
    GetLivestreamInfoResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class LivestreamsApi extends runtime.BaseAPI {

    /**
     * It returns a list of featured live streams.
     */
    async livestreamsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetLivestreamInfoResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/livestreams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetLivestreamInfoResponseFromJSON));
    }

    /**
     * It returns a list of featured live streams.
     */
    async livestreamsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetLivestreamInfoResponse>> {
        const response = await this.livestreamsGetRaw(initOverrides);
        return await response.value();
    }

}
