/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseUserResponse } from './BaseUserResponse';
import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
} from './BaseUserResponse';
import type { ChannelRole } from './ChannelRole';
import {
    ChannelRoleFromJSON,
    ChannelRoleFromJSONTyped,
    ChannelRoleToJSON,
} from './ChannelRole';
import type { GetBadgeResponse } from './GetBadgeResponse';
import {
    GetBadgeResponseFromJSON,
    GetBadgeResponseFromJSONTyped,
    GetBadgeResponseToJSON,
} from './GetBadgeResponse';
import type { GetEmoteResponse } from './GetEmoteResponse';
import {
    GetEmoteResponseFromJSON,
    GetEmoteResponseFromJSONTyped,
    GetEmoteResponseToJSON,
} from './GetEmoteResponse';
import type { GetLivestreamInfoResponse } from './GetLivestreamInfoResponse';
import {
    GetLivestreamInfoResponseFromJSON,
    GetLivestreamInfoResponseFromJSONTyped,
    GetLivestreamInfoResponseToJSON,
} from './GetLivestreamInfoResponse';
import type { GetVideoResponse } from './GetVideoResponse';
import {
    GetVideoResponseFromJSON,
    GetVideoResponseFromJSONTyped,
    GetVideoResponseToJSON,
} from './GetVideoResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetChannelInfoResponse
 */
export interface GetChannelInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    slug: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetChannelInfoResponse
     */
    bannerPicture?: ImageVariantsResponse;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isBanned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChannelInfoResponse
     */
    playbackUrl: string;
    /**
     * 
     * @type {ChannelRole}
     * @memberof GetChannelInfoResponse
     */
    role?: ChannelRole;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    isChannelOwner?: boolean;
    /**
     * 
     * @type {BaseUserResponse}
     * @memberof GetChannelInfoResponse
     */
    user: BaseUserResponse;
    /**
     * 
     * @type {number}
     * @memberof GetChannelInfoResponse
     */
    followerCount?: number;
    /**
     * 
     * @type {GetLivestreamInfoResponse}
     * @memberof GetChannelInfoResponse
     */
    livestream?: GetLivestreamInfoResponse;
    /**
     * 
     * @type {Array<GetVideoResponse>}
     * @memberof GetChannelInfoResponse
     */
    videos?: Array<GetVideoResponse> | null;
    /**
     * 
     * @type {Array<GetBadgeResponse>}
     * @memberof GetChannelInfoResponse
     */
    subscriptionBadges?: Array<GetBadgeResponse> | null;
    /**
     * 
     * @type {Array<GetEmoteResponse>}
     * @memberof GetChannelInfoResponse
     */
    customEmotes?: Array<GetEmoteResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelInfoResponse
     */
    readonly isLive?: boolean;
}

/**
 * Check if a given object implements the GetChannelInfoResponse interface.
 */
export function instanceOfGetChannelInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "playbackUrl" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GetChannelInfoResponseFromJSON(json: any): GetChannelInfoResponse {
    return GetChannelInfoResponseFromJSONTyped(json, false);
}

export function GetChannelInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'slug': json['slug'],
        'bannerPicture': !exists(json, 'bannerPicture') ? undefined : ImageVariantsResponseFromJSON(json['bannerPicture']),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isVerified': !exists(json, 'isVerified') ? undefined : json['isVerified'],
        'playbackUrl': json['playbackUrl'],
        'role': !exists(json, 'role') ? undefined : ChannelRoleFromJSON(json['role']),
        'isChannelOwner': !exists(json, 'isChannelOwner') ? undefined : json['isChannelOwner'],
        'user': BaseUserResponseFromJSON(json['user']),
        'followerCount': !exists(json, 'followerCount') ? undefined : json['followerCount'],
        'livestream': !exists(json, 'livestream') ? undefined : GetLivestreamInfoResponseFromJSON(json['livestream']),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(GetVideoResponseFromJSON)),
        'subscriptionBadges': !exists(json, 'subscriptionBadges') ? undefined : (json['subscriptionBadges'] === null ? null : (json['subscriptionBadges'] as Array<any>).map(GetBadgeResponseFromJSON)),
        'customEmotes': !exists(json, 'customEmotes') ? undefined : (json['customEmotes'] === null ? null : (json['customEmotes'] as Array<any>).map(GetEmoteResponseFromJSON)),
        'isLive': !exists(json, 'isLive') ? undefined : json['isLive'],
    };
}

export function GetChannelInfoResponseToJSON(value?: GetChannelInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'slug': value.slug,
        'bannerPicture': ImageVariantsResponseToJSON(value.bannerPicture),
        'isBanned': value.isBanned,
        'description': value.description,
        'isVerified': value.isVerified,
        'playbackUrl': value.playbackUrl,
        'role': ChannelRoleToJSON(value.role),
        'isChannelOwner': value.isChannelOwner,
        'user': BaseUserResponseToJSON(value.user),
        'followerCount': value.followerCount,
        'livestream': GetLivestreamInfoResponseToJSON(value.livestream),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(GetVideoResponseToJSON)),
        'subscriptionBadges': value.subscriptionBadges === undefined ? undefined : (value.subscriptionBadges === null ? null : (value.subscriptionBadges as Array<any>).map(GetBadgeResponseToJSON)),
        'customEmotes': value.customEmotes === undefined ? undefined : (value.customEmotes === null ? null : (value.customEmotes as Array<any>).map(GetEmoteResponseToJSON)),
    };
}

