/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetCategoryResponse,
  GetTopCategoryResponse,
  GetTopCategoryResponsePagedApiResponse,
  GetVideoCategoryResponse,
  GetVideoItemResponsePagedApiResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetCategoryResponseFromJSON,
    GetCategoryResponseToJSON,
    GetTopCategoryResponseFromJSON,
    GetTopCategoryResponseToJSON,
    GetTopCategoryResponsePagedApiResponseFromJSON,
    GetTopCategoryResponsePagedApiResponseToJSON,
    GetVideoCategoryResponseFromJSON,
    GetVideoCategoryResponseToJSON,
    GetVideoItemResponsePagedApiResponseFromJSON,
    GetVideoItemResponsePagedApiResponseToJSON,
} from '../models/index';

export interface CategoriesCategoriesMainCategorySlugVideosGetRequest {
    mainCategorySlug: string;
    pageNumber?: number;
    pageSize?: number;
    withLivestreams?: boolean;
}

export interface CategoriesCategorySlugGetRequest {
    categorySlug: string;
}

export interface CategoriesSubcategoriesGetRequest {
    pageNumber?: number;
    pageSize?: number;
}

export interface CategoriesSubcategoriesSubCategorySlugGetRequest {
    subCategorySlug: string;
}

export interface CategoriesSubcategoriesSubCategorySlugVideosGetRequest {
    subCategorySlug: string;
    pageNumber?: number;
    pageSize?: number;
    withLivestreams?: boolean;
}

/**
 * 
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     * Retrieves videos for a main category with pagination, optionally including livestreams.
     */
    async categoriesCategoriesMainCategorySlugVideosGetRaw(requestParameters: CategoriesCategoriesMainCategorySlugVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVideoItemResponsePagedApiResponse>> {
        if (requestParameters.mainCategorySlug === null || requestParameters.mainCategorySlug === undefined) {
            throw new runtime.RequiredError('mainCategorySlug','Required parameter requestParameters.mainCategorySlug was null or undefined when calling categoriesCategoriesMainCategorySlugVideosGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.withLivestreams !== undefined) {
            queryParameters['withLivestreams'] = requestParameters.withLivestreams;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/categories/{mainCategorySlug}/videos`.replace(`{${"mainCategorySlug"}}`, encodeURIComponent(String(requestParameters.mainCategorySlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVideoItemResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves videos for a main category with pagination, optionally including livestreams.
     */
    async categoriesCategoriesMainCategorySlugVideosGet(requestParameters: CategoriesCategoriesMainCategorySlugVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVideoItemResponsePagedApiResponse> {
        const response = await this.categoriesCategoriesMainCategorySlugVideosGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single category
     */
    async categoriesCategorySlugGetRaw(requestParameters: CategoriesCategorySlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCategoryResponse>> {
        if (requestParameters.categorySlug === null || requestParameters.categorySlug === undefined) {
            throw new runtime.RequiredError('categorySlug','Required parameter requestParameters.categorySlug was null or undefined when calling categoriesCategorySlugGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/{categorySlug}`.replace(`{${"categorySlug"}}`, encodeURIComponent(String(requestParameters.categorySlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a single category
     */
    async categoriesCategorySlugGet(requestParameters: CategoriesCategorySlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCategoryResponse> {
        const response = await this.categoriesCategorySlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all categories information
     */
    async categoriesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetCategoryResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCategoryResponseFromJSON));
    }

    /**
     * Retrieves all categories information
     */
    async categoriesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetCategoryResponse>> {
        const response = await this.categoriesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all subcategories information with pagination
     */
    async categoriesSubcategoriesGetRaw(requestParameters: CategoriesSubcategoriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTopCategoryResponsePagedApiResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/subcategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopCategoryResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves all subcategories information with pagination
     */
    async categoriesSubcategoriesGet(requestParameters: CategoriesSubcategoriesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTopCategoryResponsePagedApiResponse> {
        const response = await this.categoriesSubcategoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a single subcategory
     */
    async categoriesSubcategoriesSubCategorySlugGetRaw(requestParameters: CategoriesSubcategoriesSubCategorySlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCategoryResponse>> {
        if (requestParameters.subCategorySlug === null || requestParameters.subCategorySlug === undefined) {
            throw new runtime.RequiredError('subCategorySlug','Required parameter requestParameters.subCategorySlug was null or undefined when calling categoriesSubcategoriesSubCategorySlugGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/subcategories/{subCategorySlug}`.replace(`{${"subCategorySlug"}}`, encodeURIComponent(String(requestParameters.subCategorySlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves a single subcategory
     */
    async categoriesSubcategoriesSubCategorySlugGet(requestParameters: CategoriesSubcategoriesSubCategorySlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCategoryResponse> {
        const response = await this.categoriesSubcategoriesSubCategorySlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves videos for a subcategory with pagination, optionally including livestreams.
     */
    async categoriesSubcategoriesSubCategorySlugVideosGetRaw(requestParameters: CategoriesSubcategoriesSubCategorySlugVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVideoItemResponsePagedApiResponse>> {
        if (requestParameters.subCategorySlug === null || requestParameters.subCategorySlug === undefined) {
            throw new runtime.RequiredError('subCategorySlug','Required parameter requestParameters.subCategorySlug was null or undefined when calling categoriesSubcategoriesSubCategorySlugVideosGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['PageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.withLivestreams !== undefined) {
            queryParameters['withLivestreams'] = requestParameters.withLivestreams;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/subcategories/{subCategorySlug}/videos`.replace(`{${"subCategorySlug"}}`, encodeURIComponent(String(requestParameters.subCategorySlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVideoItemResponsePagedApiResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves videos for a subcategory with pagination, optionally including livestreams.
     */
    async categoriesSubcategoriesSubCategorySlugVideosGet(requestParameters: CategoriesSubcategoriesSubCategorySlugVideosGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVideoItemResponsePagedApiResponse> {
        const response = await this.categoriesSubcategoriesSubCategorySlugVideosGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves information about top categories
     */
    async categoriesTopGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetTopCategoryResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/top`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTopCategoryResponseFromJSON));
    }

    /**
     * Retrieves information about top categories
     */
    async categoriesTopGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetTopCategoryResponse>> {
        const response = await this.categoriesTopGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves information about category videos
     */
    async categoriesVideosGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVideoCategoryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/categories/videos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVideoCategoryResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves information about category videos
     */
    async categoriesVideosGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVideoCategoryResponse> {
        const response = await this.categoriesVideosGetRaw(initOverrides);
        return await response.value();
    }

}
