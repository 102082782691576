/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Post } from './Post';
import {
    PostFromJSON,
    PostFromJSONTyped,
    PostToJSON,
} from './Post';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PostLike
 */
export interface PostLike {
    /**
     * 
     * @type {Date}
     * @memberof PostLike
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PostLike
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PostLike
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PostLike
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PostLike
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PostLike
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostLike
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostLike
     */
    postId?: string;
    /**
     * 
     * @type {Post}
     * @memberof PostLike
     */
    post?: Post;
    /**
     * 
     * @type {number}
     * @memberof PostLike
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof PostLike
     */
    user?: User;
    /**
     * 
     * @type {Date}
     * @memberof PostLike
     */
    likedAt?: Date;
}

/**
 * Check if a given object implements the PostLike interface.
 */
export function instanceOfPostLike(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostLikeFromJSON(json: any): PostLike {
    return PostLikeFromJSONTyped(json, false);
}

export function PostLikeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostLike {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'postId': !exists(json, 'postId') ? undefined : json['postId'],
        'post': !exists(json, 'post') ? undefined : PostFromJSON(json['post']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'likedAt': !exists(json, 'likedAt') ? undefined : (new Date(json['likedAt'])),
    };
}

export function PostLikeToJSON(value?: PostLike | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'postId': value.postId,
        'post': PostToJSON(value.post),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'likedAt': value.likedAt === undefined ? undefined : (value.likedAt.toISOString()),
    };
}

