/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  BaseUserResponse,
  GetUserInfoResponse,
  MFAStatusResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    BaseUserResponseFromJSON,
    BaseUserResponseToJSON,
    GetUserInfoResponseFromJSON,
    GetUserInfoResponseToJSON,
    MFAStatusResponseFromJSON,
    MFAStatusResponseToJSON,
} from '../models/index';

export interface Auth0Auth0IdGetRequest {
    auth0Id: string;
}

/**
 * 
 */
export class Auth0Api extends runtime.BaseAPI {

    /**
     */
    async auth0Auth0IdGetRaw(requestParameters: Auth0Auth0IdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseUserResponse>> {
        if (requestParameters.auth0Id === null || requestParameters.auth0Id === undefined) {
            throw new runtime.RequiredError('auth0Id','Required parameter requestParameters.auth0Id was null or undefined when calling auth0Auth0IdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/{auth0Id}`.replace(`{${"auth0Id"}}`, encodeURIComponent(String(requestParameters.auth0Id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserResponseFromJSON(jsonValue));
    }

    /**
     */
    async auth0Auth0IdGet(requestParameters: Auth0Auth0IdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseUserResponse> {
        const response = await this.auth0Auth0IdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the MFA enrollment of the currently logged-in user.
     */
    async auth0MfaEnrollmentDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/mfa-enrollment`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes the MFA enrollment of the currently logged-in user.
     */
    async auth0MfaEnrollmentDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.auth0MfaEnrollmentDeleteRaw(initOverrides);
    }

    /**
     * Gets the MFA status of the currently logged-in user.
     */
    async auth0MfaStatusGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MFAStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/mfa-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MFAStatusResponseFromJSON(jsonValue));
    }

    /**
     * Gets the MFA status of the currently logged-in user.
     */
    async auth0MfaStatusGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MFAStatusResponse> {
        const response = await this.auth0MfaStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async auth0ResendVerificationEmailPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/resend-verification-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async auth0ResendVerificationEmailPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.auth0ResendVerificationEmailPostRaw(initOverrides);
    }

    /**
     * Registers user based on auth0Id and email.
     */
    async auth0SignupPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseUserResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseUserResponseFromJSON(jsonValue));
    }

    /**
     * Registers user based on auth0Id and email.
     */
    async auth0SignupPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseUserResponse> {
        const response = await this.auth0SignupPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Triggers a 2FA enrollment email for the currently logged-in user.
     */
    async auth0Trigger2faEnrollmentPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/trigger-2fa-enrollment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserInfoResponseFromJSON(jsonValue));
    }

    /**
     * Triggers a 2FA enrollment email for the currently logged-in user.
     */
    async auth0Trigger2faEnrollmentPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserInfoResponse> {
        const response = await this.auth0Trigger2faEnrollmentPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Triggers a password reset email for the currently logged-in user by calling the Auth0 password change endpoint.
     */
    async auth0TriggerPasswordResetPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/auth0/trigger-password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserInfoResponseFromJSON(jsonValue));
    }

    /**
     * Triggers a password reset email for the currently logged-in user by calling the Auth0 password change endpoint.
     */
    async auth0TriggerPasswordResetPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserInfoResponse> {
        const response = await this.auth0TriggerPasswordResetPostRaw(initOverrides);
        return await response.value();
    }

}
