/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { ContentReport } from './ContentReport';
import {
    ContentReportFromJSON,
    ContentReportFromJSONTyped,
    ContentReportToJSON,
} from './ContentReport';
import type { RecordingStatus } from './RecordingStatus';
import {
    RecordingStatusFromJSON,
    RecordingStatusFromJSONTyped,
    RecordingStatusToJSON,
} from './RecordingStatus';
import type { StreamStatus } from './StreamStatus';
import {
    StreamStatusFromJSON,
    StreamStatusFromJSONTyped,
    StreamStatusToJSON,
} from './StreamStatus';
import type { Video } from './Video';
import {
    VideoFromJSON,
    VideoFromJSONTyped,
    VideoToJSON,
} from './Video';

/**
 * 
 * @export
 * @interface StreamSession
 */
export interface StreamSession {
    /**
     * 
     * @type {Date}
     * @memberof StreamSession
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StreamSession
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StreamSession
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StreamSession
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    language?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StreamSession
     */
    isMature?: boolean;
    /**
     * 
     * @type {StreamStatus}
     * @memberof StreamSession
     */
    status?: StreamStatus;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    awsStreamSessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    awsRecordingS3BucketName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    awsRecordingS3KeyPrefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    awsRecordingSessionId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StreamSession
     */
    awsRecordingDurationInMs?: number;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    awsChannelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    playbackUrl?: string | null;
    /**
     * 
     * @type {RecordingStatus}
     * @memberof StreamSession
     */
    recordingStatus?: RecordingStatus;
    /**
     * 
     * @type {Date}
     * @memberof StreamSession
     */
    startedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StreamSession
     */
    finishedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StreamSession
     */
    bannedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StreamSession
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof StreamSession
     */
    channel?: Channel;
    /**
     * 
     * @type {Array<Video>}
     * @memberof StreamSession
     */
    videos?: Array<Video> | null;
    /**
     * 
     * @type {number}
     * @memberof StreamSession
     */
    categoryId?: number;
    /**
     * 
     * @type {Category}
     * @memberof StreamSession
     */
    category?: Category;
    /**
     * 
     * @type {Array<ContentReport>}
     * @memberof StreamSession
     */
    reports?: Array<ContentReport> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StreamSession
     */
    readonly isBanned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StreamSession
     */
    readonly isLive?: boolean;
}

/**
 * Check if a given object implements the StreamSession interface.
 */
export function instanceOfStreamSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function StreamSessionFromJSON(json: any): StreamSession {
    return StreamSessionFromJSONTyped(json, false);
}

export function StreamSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'isMature': !exists(json, 'isMature') ? undefined : json['isMature'],
        'status': !exists(json, 'status') ? undefined : StreamStatusFromJSON(json['status']),
        'awsStreamSessionId': !exists(json, 'awsStreamSessionId') ? undefined : json['awsStreamSessionId'],
        'awsRecordingS3BucketName': !exists(json, 'awsRecordingS3BucketName') ? undefined : json['awsRecordingS3BucketName'],
        'awsRecordingS3KeyPrefix': !exists(json, 'awsRecordingS3KeyPrefix') ? undefined : json['awsRecordingS3KeyPrefix'],
        'awsRecordingSessionId': !exists(json, 'awsRecordingSessionId') ? undefined : json['awsRecordingSessionId'],
        'awsRecordingDurationInMs': !exists(json, 'awsRecordingDurationInMs') ? undefined : json['awsRecordingDurationInMs'],
        'awsChannelName': !exists(json, 'awsChannelName') ? undefined : json['awsChannelName'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'playbackUrl': !exists(json, 'playbackUrl') ? undefined : json['playbackUrl'],
        'recordingStatus': !exists(json, 'recordingStatus') ? undefined : RecordingStatusFromJSON(json['recordingStatus']),
        'startedAt': !exists(json, 'startedAt') ? undefined : (json['startedAt'] === null ? null : new Date(json['startedAt'])),
        'finishedAt': !exists(json, 'finishedAt') ? undefined : (json['finishedAt'] === null ? null : new Date(json['finishedAt'])),
        'bannedAt': !exists(json, 'bannedAt') ? undefined : (json['bannedAt'] === null ? null : new Date(json['bannedAt'])),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(VideoFromJSON)),
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'reports': !exists(json, 'reports') ? undefined : (json['reports'] === null ? null : (json['reports'] as Array<any>).map(ContentReportFromJSON)),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
        'isLive': !exists(json, 'isLive') ? undefined : json['isLive'],
    };
}

export function StreamSessionToJSON(value?: StreamSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'title': value.title,
        'language': value.language,
        'isMature': value.isMature,
        'status': StreamStatusToJSON(value.status),
        'awsStreamSessionId': value.awsStreamSessionId,
        'awsRecordingS3BucketName': value.awsRecordingS3BucketName,
        'awsRecordingS3KeyPrefix': value.awsRecordingS3KeyPrefix,
        'awsRecordingSessionId': value.awsRecordingSessionId,
        'awsRecordingDurationInMs': value.awsRecordingDurationInMs,
        'awsChannelName': value.awsChannelName,
        'thumbnailUrl': value.thumbnailUrl,
        'playbackUrl': value.playbackUrl,
        'recordingStatus': RecordingStatusToJSON(value.recordingStatus),
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt === null ? null : value.startedAt.toISOString()),
        'finishedAt': value.finishedAt === undefined ? undefined : (value.finishedAt === null ? null : value.finishedAt.toISOString()),
        'bannedAt': value.bannedAt === undefined ? undefined : (value.bannedAt === null ? null : value.bannedAt.toISOString()),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(VideoToJSON)),
        'categoryId': value.categoryId,
        'category': CategoryToJSON(value.category),
        'reports': value.reports === undefined ? undefined : (value.reports === null ? null : (value.reports as Array<any>).map(ContentReportToJSON)),
    };
}

