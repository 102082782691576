/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetVideoResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetVideoResponseFromJSON,
    GetVideoResponseToJSON,
} from '../models/index';

export interface VideoVideoIdDeleteRequest {
    videoId: string;
}

export interface VideoVideoIdGetRequest {
    videoId: string;
}

/**
 * 
 */
export class VideoApi extends runtime.BaseAPI {

    /**
     * Deletes a video by its id.
     */
    async videoVideoIdDeleteRaw(requestParameters: VideoVideoIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling videoVideoIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/video/{videoId}`.replace(`{${"videoId"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a video by its id.
     */
    async videoVideoIdDelete(requestParameters: VideoVideoIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.videoVideoIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves information about a video by its id.
     */
    async videoVideoIdGetRaw(requestParameters: VideoVideoIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetVideoResponse>> {
        if (requestParameters.videoId === null || requestParameters.videoId === undefined) {
            throw new runtime.RequiredError('videoId','Required parameter requestParameters.videoId was null or undefined when calling videoVideoIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/video/{videoId}`.replace(`{${"videoId"}}`, encodeURIComponent(String(requestParameters.videoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVideoResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves information about a video by its id.
     */
    async videoVideoIdGet(requestParameters: VideoVideoIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetVideoResponse> {
        const response = await this.videoVideoIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
