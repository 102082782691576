/* eslint-disable sonarjs/no-unstable-nested-components */
import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './shared/components/Layout/Layout'
import { routes } from './routes'

import LoadingScreen from './shared/components/Common/LoadingScreen'
import { onlyFans, onlyStream } from './shared/utils/sites'
import EmptyLayout from './shared/components/Layout/EmptyLayout'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthenticationGuard } from './shared/utils/AuthenticationGuard'
import WithSideColumnLayout from './OnlyStream/components/WithSideColumnLayout'
import { UserContextProvider } from './OnlyStream/stores/useUser'

// Common:
const PageNotFound = lazy(
  () => import('./shared/pages/error/NotFoundErrorPage')
)
const CompleteRegistration = lazy(
  () => import('./OnlyStream/pages/CompleteRegistrationPage')
)
const WithRegistrationCheck = lazy(
  () => import('./shared/utils/WithRegistrationCheck')
)
const Login = lazy(() => import('./OnlyStream/pages/LoginPage'))
const Logout = lazy(() => import('./OnlyStream/pages/LogoutPage'))
const SignUp = lazy(() => import('./OnlyStream/pages/SignUpPage'))
const MyStream = lazy(() => import('./OnlyStream/pages/MyStreamPage'))
const MyProfile = lazy(() => import('./OnlyStream/pages/MyProfile'))
const ProfilePage = lazy(() => import('./OnlyStream/pages/ProfilePage'))
const SingleStream = lazy(() => import('./OnlyStream/pages/ChannelPage'))
const SingleVideo = lazy(() => import('./OnlyStream/pages/VideoPage'))
const Subscriptions = lazy(() => import('./OnlyStream/pages/SubscriptionsPage'))
const StreamKey = lazy(() => import('./OnlyStream/pages/StreamKeyPage'))
const Achievements = lazy(() => import('./OnlyStream/pages/AchievementsPage'))
const Badges = lazy(() => import('./OnlyStream/pages/BadgesPage'))
const Emotes = lazy(() => import('./OnlyStream/pages/EmotesPage'))
const ModeratorsPage = lazy(() => import('./OnlyStream/pages/ModeratorsPage'))
const StoryblokStatic = lazy(
  () => import('./OnlyStream/pages/StroyblokStaticPage')
)
const EmbedPage = lazy(() => import('./OnlyStream/pages/EmbedPage'))
const Callback = lazy(() => import('./OnlyStream/pages/CallbackPage'))
const Security = lazy(() => import('./OnlyStream/pages/SecurityPage'))

// OnlyStream:
const StreamHome = lazy(() => import('./OnlyStream/pages/Home'))
const Following = lazy(() => import('./OnlyStream/pages/Following'))
const CategoriesPage = lazy(() => import('./OnlyStream/pages/CategoriesPage'))
const CategoryPage = lazy(() => import('./OnlyStream/pages/CategoryPage'))
const SubcategoryPage = lazy(() => import('./OnlyStream/pages/SubcategoryPage'))
const ChatPage = lazy(
  () => import('./OnlyStream/components/Channel/Chat/ChatPage')
)
// OnlyFans:
const FansHome = lazy(() => import('./OnlyFans/pages/Home'))
const FansChannelPage = lazy(() => import('./OnlyFans/pages/ChannelPage'))
const FeedsPage = lazy(() => import('./OnlyStream/pages/FeedsPage'))

export const Router = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <Suspense fallback={<LoadingScreen />}>
      <UserContextProvider>
        <Routes>
          <Route
            path={routes.embed.embed(':slug')}
            element={
              <EmptyLayout>
                <EmbedPage />
              </EmptyLayout>
            }
          />
          <Route path={routes.signup} element={<SignUp />} />
          <Route path={routes.logout} element={<Logout />} />
          <Route path={routes.login} element={<Login />} />
          <Route
            path="*"
            element={
              <Layout>
                <WithRegistrationCheck>
                  <Routes>
                    {onlyStream(
                      <>
                        <Route
                          path={routes.communityGuidelines}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.termsOfService}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.dmcaPolicy}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.privacyPolicy}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.howToEmbed}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.home}
                          element={
                            <WithSideColumnLayout>
                              <StreamHome />
                            </WithSideColumnLayout>
                          }
                        />
                        <Route
                          path={routes.categories()}
                          element={
                            <WithSideColumnLayout>
                              <CategoriesPage />
                            </WithSideColumnLayout>
                          }
                        />
                        <Route
                          path={routes.categories(':category')}
                          element={
                            <WithSideColumnLayout>
                              <CategoryPage />
                            </WithSideColumnLayout>
                          }
                        />
                        <Route
                          path={routes.categories(':category', ':subcategory')}
                          element={
                            <WithSideColumnLayout>
                              <SubcategoryPage />
                            </WithSideColumnLayout>
                          }
                        />
                        {isAuthenticated && (
                          <Route
                            path={routes.community.moderators}
                            element={
                              <WithSideColumnLayout>
                                <ModeratorsPage />
                              </WithSideColumnLayout>
                            }
                          />
                        )}
                        <Route
                          path={routes.channel.chat(':slug')}
                          element={<ChatPage />}
                        />
                        {isAuthenticated && (
                          <>
                            <Route
                              path={routes.streams.following}
                              element={
                                <AuthenticationGuard
                                  component={() => (
                                    <WithSideColumnLayout>
                                      <Following />
                                    </WithSideColumnLayout>
                                  )}
                                />
                              }
                            />
                            <Route
                              path={routes.auth.username}
                              element={
                                <AuthenticationGuard
                                  component={() => <CompleteRegistration />}
                                />
                              }
                            />
                            <Route
                              path={routes.profileRoutes.security}
                              element={
                                <AuthenticationGuard
                                  component={() => <Security />}
                                />
                              }
                            />
                          </>
                        )}
                        <Route
                          path={routes.streams.list(':page')}
                          element={
                            <WithSideColumnLayout>
                              <StreamHome />
                            </WithSideColumnLayout>
                          }
                        />
                        <Route
                          path={routes.profile}
                          element={
                            <AuthenticationGuard
                              component={() => (
                                <WithSideColumnLayout>
                                  <ProfilePage />
                                </WithSideColumnLayout>
                              )}
                            />
                          }
                        />
                        <Route
                          path={routes.feeds}
                          element={
                            <AuthenticationGuard
                              component={() => (
                                <WithSideColumnLayout>
                                  <FeedsPage />
                                </WithSideColumnLayout>
                              )}
                            />
                          }
                        />
                      </>
                    )}
                    {onlyFans(
                      <>
                        <Route
                          path={routes.communityGuidelines}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.termsOfService}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.dmcaPolicy}
                          element={<StoryblokStatic />}
                        />
                        <Route
                          path={routes.privacyPolicy}
                          element={<StoryblokStatic />}
                        />
                        <Route path={routes.home} element={<FansHome />} />
                        <Route
                          path={routes.channel.homeShort(':slug')}
                          element={<FansChannelPage />}
                        />
                      </>
                    )}
                    <Route path={routes.auth.callback} element={<Callback />} />
                    <Route
                      path={routes.profileRoutes.myStream}
                      element={<AuthenticationGuard component={MyStream} />}
                    />
                    <Route
                      path={routes.profileRoutes.myProfile}
                      element={<AuthenticationGuard component={MyProfile} />}
                    />
                    <Route
                      path={routes.profileRoutes.streamKey}
                      element={<AuthenticationGuard component={StreamKey} />}
                    />
                    <Route
                      path={routes.profileRoutes.achievements}
                      element={<AuthenticationGuard component={Achievements} />}
                    />
                    <Route
                      path={routes.profileRoutes.badges}
                      element={<AuthenticationGuard component={Badges} />}
                    />
                    <Route
                      path={routes.profileRoutes.emotes}
                      element={<AuthenticationGuard component={Emotes} />}
                    />
                    <Route
                      path={routes.profileRoutes.subscriptions}
                      element={
                        <AuthenticationGuard component={Subscriptions} />
                      }
                    />
                    <Route
                      path={routes.embed.embed(':slug')}
                      element={<EmbedPage />}
                    />
                    <Route
                      path={routes.channel.videoFull(':slug', ':videoId')}
                      element={
                        <WithSideColumnLayout>
                          <SingleVideo />
                        </WithSideColumnLayout>
                      }
                    />
                    <Route
                      path={routes.channel.homeFull(':slug')}
                      element={
                        <WithSideColumnLayout>
                          <SingleStream />
                        </WithSideColumnLayout>
                      }
                    />
                    <Route
                      path={routes.channel.videoShort(':slug', ':videoId')}
                      element={
                        <WithSideColumnLayout>
                          <SingleVideo />
                        </WithSideColumnLayout>
                      }
                    />
                    <Route
                      path={routes.channel.homeShort(':slug')}
                      element={
                        <WithSideColumnLayout>
                          <SingleStream />
                        </WithSideColumnLayout>
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </WithRegistrationCheck>
              </Layout>
            }
          />
        </Routes>
      </UserContextProvider>
    </Suspense>
  )
}
