/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetLivestreamInfoResponse } from './GetLivestreamInfoResponse';
import {
    GetLivestreamInfoResponseFromJSON,
    GetLivestreamInfoResponseFromJSONTyped,
    GetLivestreamInfoResponseToJSON,
} from './GetLivestreamInfoResponse';
import type { GetUserInfoResponse } from './GetUserInfoResponse';
import {
    GetUserInfoResponseFromJSON,
    GetUserInfoResponseFromJSONTyped,
    GetUserInfoResponseToJSON,
} from './GetUserInfoResponse';

/**
 * 
 * @export
 * @interface GetLivestreamResponse
 */
export interface GetLivestreamResponse {
    /**
     * 
     * @type {GetUserInfoResponse}
     * @memberof GetLivestreamResponse
     */
    user: GetUserInfoResponse;
    /**
     * 
     * @type {GetLivestreamInfoResponse}
     * @memberof GetLivestreamResponse
     */
    livestream?: GetLivestreamInfoResponse;
}

/**
 * Check if a given object implements the GetLivestreamResponse interface.
 */
export function instanceOfGetLivestreamResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GetLivestreamResponseFromJSON(json: any): GetLivestreamResponse {
    return GetLivestreamResponseFromJSONTyped(json, false);
}

export function GetLivestreamResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLivestreamResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': GetUserInfoResponseFromJSON(json['user']),
        'livestream': !exists(json, 'livestream') ? undefined : GetLivestreamInfoResponseFromJSON(json['livestream']),
    };
}

export function GetLivestreamResponseToJSON(value?: GetLivestreamResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': GetUserInfoResponseToJSON(value.user),
        'livestream': GetLivestreamInfoResponseToJSON(value.livestream),
    };
}

