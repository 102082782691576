import { clarity } from 'react-microsoft-clarity'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'

const isLocalhost = window.location.hostname === 'localhost'

export const initializeTracking = () => {
  if (isLocalhost) return

  // Google Analytics
  ReactGA.initialize(`${import.meta.env.VITE_GOOGLE_ANALYTICS_ID}`)

  // Microsoft Clarity
  clarity.init(`${import.meta.env.VITE_MICROSOFT_CLARITY_ID}`)

  // Sentry
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.rewriteFramesIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', import.meta.env.VITE_SITE_BASE_URL],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
