/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { Post } from './Post';
import {
    PostFromJSON,
    PostFromJSONTyped,
    PostToJSON,
} from './Post';

/**
 * 
 * @export
 * @interface PostAsset
 */
export interface PostAsset {
    /**
     * 
     * @type {Date}
     * @memberof PostAsset
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PostAsset
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PostAsset
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PostAsset
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostAsset
     */
    postId?: string;
    /**
     * 
     * @type {Post}
     * @memberof PostAsset
     */
    post?: Post;
    /**
     * 
     * @type {string}
     * @memberof PostAsset
     */
    assetId?: string;
    /**
     * 
     * @type {Asset}
     * @memberof PostAsset
     */
    asset?: Asset;
    /**
     * 
     * @type {number}
     * @memberof PostAsset
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof PostAsset
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostAsset
     */
    isLocked?: boolean;
}

/**
 * Check if a given object implements the PostAsset interface.
 */
export function instanceOfPostAsset(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostAssetFromJSON(json: any): PostAsset {
    return PostAssetFromJSONTyped(json, false);
}

export function PostAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'postId': !exists(json, 'postId') ? undefined : json['postId'],
        'post': !exists(json, 'post') ? undefined : PostFromJSON(json['post']),
        'assetId': !exists(json, 'assetId') ? undefined : json['assetId'],
        'asset': !exists(json, 'asset') ? undefined : AssetFromJSON(json['asset']),
        'order': !exists(json, 'order') ? undefined : json['order'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isLocked': !exists(json, 'isLocked') ? undefined : json['isLocked'],
    };
}

export function PostAssetToJSON(value?: PostAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'postId': value.postId,
        'post': PostToJSON(value.post),
        'assetId': value.assetId,
        'asset': AssetToJSON(value.asset),
        'order': value.order,
        'description': value.description,
        'isLocked': value.isLocked,
    };
}

