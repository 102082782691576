import { ReactNode, createContext, useContext, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { GetUserInfoResponse } from '../../client'
import { useQuery } from '../../shared/utils/AsyncUtils/useQuery'
import { useInvalidator } from '../../shared/utils/AsyncUtils/useInvalidator'
import { useApi } from '../../shared/utils/ApiContext'

export const USER_INVALIDATOR = 'OWN_CHANNEL'

type User = {
  isLoading: boolean
  isError: boolean
  data?: GetUserInfoResponse | null
}

const UserContext = createContext<User | null>(null)

interface IUserContext {
  isLoading: boolean
  isError: boolean
  data?: GetUserInfoResponse | null
}

export function useUserContext() {
  const currentContext = useContext<IUserContext | null>(UserContext)

  if (currentContext == null) {
    throw new Error('Context used outside of a provider')
  }
  return currentContext as IUserContext
}

export function useUserContextData() {
  const currentContext = useContext<IUserContext | null>(UserContext)
  if (currentContext == null) {
    throw new Error('Context used outside of a provider')
  }
  if (currentContext.data == null && currentContext.isLoading === false) {
    throw new Error('context data does not exist')
  }
  return currentContext.data!
}

export function UserContextProvider(props: { children: ReactNode }) {
  const {
    isAuthenticated,
    isLoading: authLoading,
    error: authError,
  } = useAuth0()
  const userInvalidator = useInvalidator(USER_INVALIDATOR)
  const { userApi } = useApi()

  const userQuery = useQuery(async () => {
    if (isAuthenticated) {
      return await userApi.userGet()
    }
    return null
  }, [userInvalidator.id, isAuthenticated])

  const isLoading = authLoading || userQuery.state === 'loading'
  const isError = !!authError || (!!isAuthenticated && !userQuery.data)

  const contextValue = useMemo(
    () => ({
      isLoading,
      isError,
      data: isAuthenticated ? userQuery.data : null,
    }),
    [isLoading, isError, isAuthenticated, userQuery.data]
  )

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  )
}
