/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CloudflareResumableTusUploadResult,
  UploadImageResponse,
  UploadVideoResponse,
} from '../models/index';
import {
    CloudflareResumableTusUploadResultFromJSON,
    CloudflareResumableTusUploadResultToJSON,
    UploadImageResponseFromJSON,
    UploadImageResponseToJSON,
    UploadVideoResponseFromJSON,
    UploadVideoResponseToJSON,
} from '../models/index';

export interface UploadFinalizeUploadCloudflareVideoIdPostRequest {
    cloudflareVideoId: string;
}

export interface UploadUploadImagePostRequest {
    image: Blob;
}

export interface UploadUrlPostRequest {
    uploadLength?: number;
    uploadMetadata?: string;
}

/**
 * 
 */
export class UploadApi extends runtime.BaseAPI {

    /**
     */
    async uploadFinalizeUploadCloudflareVideoIdPostRaw(requestParameters: UploadFinalizeUploadCloudflareVideoIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadVideoResponse>> {
        if (requestParameters.cloudflareVideoId === null || requestParameters.cloudflareVideoId === undefined) {
            throw new runtime.RequiredError('cloudflareVideoId','Required parameter requestParameters.cloudflareVideoId was null or undefined when calling uploadFinalizeUploadCloudflareVideoIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/upload/finalize-upload/{cloudflareVideoId}`.replace(`{${"cloudflareVideoId"}}`, encodeURIComponent(String(requestParameters.cloudflareVideoId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadVideoResponseFromJSON(jsonValue));
    }

    /**
     */
    async uploadFinalizeUploadCloudflareVideoIdPost(requestParameters: UploadFinalizeUploadCloudflareVideoIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadVideoResponse> {
        const response = await this.uploadFinalizeUploadCloudflareVideoIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadUploadImagePostRaw(requestParameters: UploadUploadImagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadImageResponse>> {
        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling uploadUploadImagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/upload/upload-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadImageResponseFromJSON(jsonValue));
    }

    /**
     */
    async uploadUploadImagePost(requestParameters: UploadUploadImagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadImageResponse> {
        const response = await this.uploadUploadImagePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadUrlPostRaw(requestParameters: UploadUrlPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloudflareResumableTusUploadResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.uploadLength !== undefined && requestParameters.uploadLength !== null) {
            headerParameters['Upload-Length'] = String(requestParameters.uploadLength);
        }

        if (requestParameters.uploadMetadata !== undefined && requestParameters.uploadMetadata !== null) {
            headerParameters['Upload-Metadata'] = String(requestParameters.uploadMetadata);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/upload/url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloudflareResumableTusUploadResultFromJSON(jsonValue));
    }

    /**
     */
    async uploadUrlPost(requestParameters: UploadUrlPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloudflareResumableTusUploadResult> {
        const response = await this.uploadUrlPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
