/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseCategoryResponse } from './BaseCategoryResponse';
import {
    BaseCategoryResponseFromJSON,
    BaseCategoryResponseFromJSONTyped,
    BaseCategoryResponseToJSON,
} from './BaseCategoryResponse';
import type { BaseTagResponse } from './BaseTagResponse';
import {
    BaseTagResponseFromJSON,
    BaseTagResponseFromJSONTyped,
    BaseTagResponseToJSON,
} from './BaseTagResponse';

/**
 * 
 * @export
 * @interface GetVideoResponse
 */
export interface GetVideoResponse {
    /**
     * Unique identifier for the video.
     * @type {string}
     * @memberof GetVideoResponse
     */
    id: string;
    /**
     * Duration of the video in ms.
     * @type {number}
     * @memberof GetVideoResponse
     */
    duration: number;
    /**
     * Indicates whether the video is intended for mature audiences.
     * @type {boolean}
     * @memberof GetVideoResponse
     */
    isMature: boolean;
    /**
     * The title of the video.
     * @type {string}
     * @memberof GetVideoResponse
     */
    title: string;
    /**
     * The language of the video content.
     * @type {string}
     * @memberof GetVideoResponse
     */
    language: string;
    /**
     * URL to the video thumbnail image.
     * @type {string}
     * @memberof GetVideoResponse
     */
    thumbnailUrl?: string | null;
    /**
     * URL from which the video can be streamed.
     * @type {string}
     * @memberof GetVideoResponse
     */
    sourceUrl: string;
    /**
     * The date and time when the video started.
     * @type {Date}
     * @memberof GetVideoResponse
     */
    startedAt: Date;
    /**
     * The date and time when the video finished.
     * @type {Date}
     * @memberof GetVideoResponse
     */
    finishedAt: Date;
    /**
     * 
     * @type {BaseCategoryResponse}
     * @memberof GetVideoResponse
     */
    category: BaseCategoryResponse;
    /**
     * 
     * @type {Array<BaseTagResponse>}
     * @memberof GetVideoResponse
     */
    tags: Array<BaseTagResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof GetVideoResponse
     */
    isLivestream?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetVideoResponse
     */
    canDelete: boolean;
}

/**
 * Check if a given object implements the GetVideoResponse interface.
 */
export function instanceOfGetVideoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "isMature" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "sourceUrl" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "finishedAt" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "canDelete" in value;

    return isInstance;
}

export function GetVideoResponseFromJSON(json: any): GetVideoResponse {
    return GetVideoResponseFromJSONTyped(json, false);
}

export function GetVideoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVideoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'duration': json['duration'],
        'isMature': json['isMature'],
        'title': json['title'],
        'language': json['language'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'sourceUrl': json['sourceUrl'],
        'startedAt': (new Date(json['startedAt'])),
        'finishedAt': (new Date(json['finishedAt'])),
        'category': BaseCategoryResponseFromJSON(json['category']),
        'tags': ((json['tags'] as Array<any>).map(BaseTagResponseFromJSON)),
        'isLivestream': !exists(json, 'isLivestream') ? undefined : json['isLivestream'],
        'canDelete': json['canDelete'],
    };
}

export function GetVideoResponseToJSON(value?: GetVideoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'duration': value.duration,
        'isMature': value.isMature,
        'title': value.title,
        'language': value.language,
        'thumbnailUrl': value.thumbnailUrl,
        'sourceUrl': value.sourceUrl,
        'startedAt': (value.startedAt.toISOString()),
        'finishedAt': (value.finishedAt.toISOString()),
        'category': BaseCategoryResponseToJSON(value.category),
        'tags': ((value.tags as Array<any>).map(BaseTagResponseToJSON)),
        'isLivestream': value.isLivestream,
        'canDelete': value.canDelete,
    };
}

