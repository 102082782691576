/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddModeratorByDisplayNameRequest,
  AddModeratorByIdRequest,
  ApiErrorResponse,
  GetModeratorResponse,
  RemoveModeratorByDisplayNameRequest,
  RemoveModeratorByIdRequest,
} from '../models/index';
import {
    AddModeratorByDisplayNameRequestFromJSON,
    AddModeratorByDisplayNameRequestToJSON,
    AddModeratorByIdRequestFromJSON,
    AddModeratorByIdRequestToJSON,
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetModeratorResponseFromJSON,
    GetModeratorResponseToJSON,
    RemoveModeratorByDisplayNameRequestFromJSON,
    RemoveModeratorByDisplayNameRequestToJSON,
    RemoveModeratorByIdRequestFromJSON,
    RemoveModeratorByIdRequestToJSON,
} from '../models/index';

export interface ChannelmoderatorChannelsChannelIdModeratorsByDisplayNameDeleteRequest {
    channelId: string;
    removeModeratorByDisplayNameRequest?: RemoveModeratorByDisplayNameRequest;
}

export interface ChannelmoderatorChannelsChannelIdModeratorsByDisplayNamePostRequest {
    channelId: string;
    addModeratorByDisplayNameRequest?: AddModeratorByDisplayNameRequest;
}

export interface ChannelmoderatorChannelsChannelIdModeratorsByIdDeleteRequest {
    channelId: string;
    removeModeratorByIdRequest?: RemoveModeratorByIdRequest;
}

export interface ChannelmoderatorChannelsChannelIdModeratorsByIdPostRequest {
    channelId: string;
    addModeratorByIdRequest?: AddModeratorByIdRequest;
}

export interface ChannelmoderatorChannelsChannelIdModeratorsGetRequest {
    channelId: string;
    includeChannelOwner?: boolean;
}

/**
 * 
 */
export class ChannelModeratorApi extends runtime.BaseAPI {

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByDisplayNameDeleteRaw(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByDisplayNameDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelmoderatorChannelsChannelIdModeratorsByDisplayNameDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channelmoderator/channels/{channelId}/moderators/by-display-name`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveModeratorByDisplayNameRequestToJSON(requestParameters.removeModeratorByDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByDisplayNameDelete(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByDisplayNameDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelmoderatorChannelsChannelIdModeratorsByDisplayNameDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByDisplayNamePostRaw(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByDisplayNamePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelmoderatorChannelsChannelIdModeratorsByDisplayNamePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channelmoderator/channels/{channelId}/moderators/by-display-name`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddModeratorByDisplayNameRequestToJSON(requestParameters.addModeratorByDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByDisplayNamePost(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByDisplayNamePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelmoderatorChannelsChannelIdModeratorsByDisplayNamePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByIdDeleteRaw(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelmoderatorChannelsChannelIdModeratorsByIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channelmoderator/channels/{channelId}/moderators/by-id`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveModeratorByIdRequestToJSON(requestParameters.removeModeratorByIdRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByIdDelete(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelmoderatorChannelsChannelIdModeratorsByIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByIdPostRaw(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelmoderatorChannelsChannelIdModeratorsByIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channelmoderator/channels/{channelId}/moderators/by-id`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddModeratorByIdRequestToJSON(requestParameters.addModeratorByIdRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsByIdPost(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsByIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelmoderatorChannelsChannelIdModeratorsByIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsGetRaw(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetModeratorResponse>>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelmoderatorChannelsChannelIdModeratorsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeChannelOwner !== undefined) {
            queryParameters['IncludeChannelOwner'] = requestParameters.includeChannelOwner;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/channelmoderator/channels/{channelId}/moderators`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetModeratorResponseFromJSON));
    }

    /**
     */
    async channelmoderatorChannelsChannelIdModeratorsGet(requestParameters: ChannelmoderatorChannelsChannelIdModeratorsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetModeratorResponse>> {
        const response = await this.channelmoderatorChannelsChannelIdModeratorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
