/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostAsset } from './PostAsset';
import {
    PostAssetFromJSON,
    PostAssetFromJSONTyped,
    PostAssetToJSON,
} from './PostAsset';
import type { PostLike } from './PostLike';
import {
    PostLikeFromJSON,
    PostLikeFromJSONTyped,
    PostLikeToJSON,
} from './PostLike';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * 
     * @type {Date}
     * @memberof Post
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Post
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Post
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Post
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    authorId?: number;
    /**
     * 
     * @type {User}
     * @memberof Post
     */
    author?: User;
    /**
     * 
     * @type {boolean}
     * @memberof Post
     */
    isPublished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Post
     */
    isLocked?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Post
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Post
     */
    scheduledAt?: Date | null;
    /**
     * 
     * @type {Array<PostAsset>}
     * @memberof Post
     */
    postAssets?: Array<PostAsset> | null;
    /**
     * 
     * @type {Array<PostLike>}
     * @memberof Post
     */
    likes?: Array<PostLike> | null;
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    readonly likeCount?: number;
}

/**
 * Check if a given object implements the Post interface.
 */
export function instanceOfPost(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostFromJSON(json: any): Post {
    return PostFromJSONTyped(json, false);
}

export function PostFromJSONTyped(json: any, ignoreDiscriminator: boolean): Post {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'authorId': !exists(json, 'authorId') ? undefined : json['authorId'],
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
        'isPublished': !exists(json, 'isPublished') ? undefined : json['isPublished'],
        'isLocked': !exists(json, 'isLocked') ? undefined : json['isLocked'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (json['publishedAt'] === null ? null : new Date(json['publishedAt'])),
        'scheduledAt': !exists(json, 'scheduledAt') ? undefined : (json['scheduledAt'] === null ? null : new Date(json['scheduledAt'])),
        'postAssets': !exists(json, 'postAssets') ? undefined : (json['postAssets'] === null ? null : (json['postAssets'] as Array<any>).map(PostAssetFromJSON)),
        'likes': !exists(json, 'likes') ? undefined : (json['likes'] === null ? null : (json['likes'] as Array<any>).map(PostLikeFromJSON)),
        'likeCount': !exists(json, 'likeCount') ? undefined : json['likeCount'],
    };
}

export function PostToJSON(value?: Post | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'text': value.text,
        'authorId': value.authorId,
        'author': UserToJSON(value.author),
        'isPublished': value.isPublished,
        'isLocked': value.isLocked,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt === null ? null : value.publishedAt.toISOString()),
        'scheduledAt': value.scheduledAt === undefined ? undefined : (value.scheduledAt === null ? null : value.scheduledAt.toISOString()),
        'postAssets': value.postAssets === undefined ? undefined : (value.postAssets === null ? null : (value.postAssets as Array<any>).map(PostAssetToJSON)),
        'likes': value.likes === undefined ? undefined : (value.likes === null ? null : (value.likes as Array<any>).map(PostLikeToJSON)),
    };
}

