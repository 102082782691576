/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetActivityFeedEventResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetActivityFeedEventResponseFromJSON,
    GetActivityFeedEventResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class ActivityFeedApi extends runtime.BaseAPI {

    /**
     * Retrieves the activity feed events for the last 1 day for a specific channel.
     */
    async activityfeedLastDayGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetActivityFeedEventResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/activityfeed/last-day`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetActivityFeedEventResponseFromJSON));
    }

    /**
     * Retrieves the activity feed events for the last 1 day for a specific channel.
     */
    async activityfeedLastDayGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetActivityFeedEventResponse>> {
        const response = await this.activityfeedLastDayGetRaw(initOverrides);
        return await response.value();
    }

}
