/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetChatTokenResponse,
} from '../models/index';
import {
    GetChatTokenResponseFromJSON,
    GetChatTokenResponseToJSON,
} from '../models/index';

export interface ChatSlugGetRequest {
    slug: string;
}

/**
 * 
 */
export class ChatApi extends runtime.BaseAPI {

    /**
     * Retrieves access token to connect to the slug\'s chat.
     */
    async chatSlugGetRaw(requestParameters: ChatSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChatTokenResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling chatSlugGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/chat/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChatTokenResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves access token to connect to the slug\'s chat.
     */
    async chatSlugGet(requestParameters: ChatSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChatTokenResponse> {
        const response = await this.chatSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
