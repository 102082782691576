/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateChannelReportRequest,
  CreateStreamSessionReportRequest,
  CreateVideoReportRequest,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    CreateChannelReportRequestFromJSON,
    CreateChannelReportRequestToJSON,
    CreateStreamSessionReportRequestFromJSON,
    CreateStreamSessionReportRequestToJSON,
    CreateVideoReportRequestFromJSON,
    CreateVideoReportRequestToJSON,
} from '../models/index';

export interface ContentreportChannelPostRequest {
    createChannelReportRequest?: CreateChannelReportRequest;
}

export interface ContentreportStreamsessionPostRequest {
    createStreamSessionReportRequest?: CreateStreamSessionReportRequest;
}

export interface ContentreportVideoPostRequest {
    createVideoReportRequest?: CreateVideoReportRequest;
}

/**
 * 
 */
export class ContentReportApi extends runtime.BaseAPI {

    /**
     * Reports a channel.
     */
    async contentreportChannelPostRaw(requestParameters: ContentreportChannelPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/contentreport/channel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChannelReportRequestToJSON(requestParameters.createChannelReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reports a channel.
     */
    async contentreportChannelPost(requestParameters: ContentreportChannelPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contentreportChannelPostRaw(requestParameters, initOverrides);
    }

    /**
     * Reports a stream session.
     */
    async contentreportStreamsessionPostRaw(requestParameters: ContentreportStreamsessionPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/contentreport/streamsession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStreamSessionReportRequestToJSON(requestParameters.createStreamSessionReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reports a stream session.
     */
    async contentreportStreamsessionPost(requestParameters: ContentreportStreamsessionPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contentreportStreamsessionPostRaw(requestParameters, initOverrides);
    }

    /**
     * Reports a video.
     */
    async contentreportVideoPostRaw(requestParameters: ContentreportVideoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/contentreport/video`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVideoReportRequestToJSON(requestParameters.createVideoReportRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reports a video.
     */
    async contentreportVideoPost(requestParameters: ContentreportVideoPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contentreportVideoPostRaw(requestParameters, initOverrides);
    }

}
