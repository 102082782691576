import useLanguage from '../../../stores/useLanguage'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '../../Common/Form/Button'

export function LoginAndRegisterButtons() {
  const { t, languageCode } = useLanguage()
  const { loginWithRedirect } = useAuth0()

  const handleLogin = async () => {
    await loginWithRedirect({
      authorizationParams: {
        ui_locales: languageCode,
      },
    })
  }

  const handleSignUp = async () => {
    await loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        ui_locales: languageCode,
      },
    })
  }

  return (
    <div className="flex justify-end gap-4 items-center">
      <Button
        type="submit"
        className="bg-symbol font-medium w-28 h-11 rounded-lg hover:opacity-80"
        onClick={handleLogin}
      >
        {t('accountRelated.login')}
      </Button>
      <Button
        type="submit"
        className="bg-background-secondary font-medium w-28 h-11 rounded-lg hover:opacity-80"
        onClick={handleSignUp}
      >
        {t('accountRelated.register')}
      </Button>
    </div>
  )
}
