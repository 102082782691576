import { Menu } from '@headlessui/react'
import { HiMenu, HiOutlineLogout as LogoutIcon } from 'react-icons/hi'
import { routes } from '../../../../routes'
import { Link } from 'react-router-dom'
import UserIcon from '../../../../assets/icons/UserIcon.svg?react'
import { styled } from '../../../../stitches'
import { RocketIcon, StarFilledIcon, GearIcon } from '@radix-ui/react-icons'
import { useAuth0 } from '@auth0/auth0-react'
import useLanguage from '../../../stores/useLanguage'
import { useUserContext } from '../../../../OnlyStream/stores/useUser'

export function ProfileMenu() {
  const { t } = useLanguage()
  const { logout } = useAuth0()

  const { data } = useUserContext()

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  return (
    <Menu>
      <MenuContainer visible={{ '@initial': false, '@tablet': true }}>
        <StyledMenuButton>
          <UserIcon />
        </StyledMenuButton>
        <StyledMenuItems>
          <Menu.Item>
            <StyledLink
              to={routes.channel.home(data?.streamerChannel?.slug || '')}
            >
              <HiMenu />
              {t('userMenu.channel')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.myProfile}>
              <HiMenu />
              {t('userMenu.profile')}
            </StyledLink>
          </Menu.Item>
          <MenuItemContainer>
            <Menu.Item>
              <StyledLink to={routes.profileRoutes.myStream}>
                <HiMenu />
                {t('userMenu.myStream')}
              </StyledLink>
            </Menu.Item>
          </MenuItemContainer>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.subscriptions}>
              <StarFilledIcon />
              {t('userMenu.subscriptions')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.streamKey}>
              <StarFilledIcon />
              {t('userMenu.streamKey')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.community.moderators}>
              <StarFilledIcon />
              {t('navbarItems.community')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.badges}>
              <StarFilledIcon />
              {t('userMenu.badges')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.emotes}>
              <StarFilledIcon />
              {t('userMenu.emotes')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.achievements}>
              <RocketIcon />
              {t('userMenu.achievements')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink to={routes.profileRoutes.security}>
              <GearIcon />
              {t('userMenu.security')}
            </StyledLink>
          </Menu.Item>
          <Menu.Item>
            <StyledLink as="a" href="#" onClick={handleLogout}>
              <LogoutIcon />
              {t('userMenu.logout')}
            </StyledLink>
          </Menu.Item>
        </StyledMenuItems>
      </MenuContainer>
    </Menu>
  )
}

const MenuItemContainer = styled('div', {
  '@phone': {
    display: 'none',
  },
  '@tablet': {
    display: 'none',
  },
  '@desktop': {
    display: 'block',
  },
})

const StyledLink = styled(Link, {
  color: '#fff',
  textDecoration: 'none',
  fontWeight: '600',
  padding: '0.5em 1em',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  borderRadius: '0.5rem',
  '&:hover': {
    background: 'rgba(255,255,255,0.2)',
  },
  '& svg': {
    marginRight: '0.5em',
  },
})

const MenuContainer = styled('div', {
  height: 44,
  alignContent: 'center',
  position: 'relative',
  variants: {
    visible: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const StyledMenuButton = styled(Menu.Button, {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
})

const StyledMenuItems = styled(Menu.Items, {
  background: '#5f5bfe',
  borderRadius: '0.5rem',
  position: 'absolute',
  padding: '0.3em',
  zIndex: 56,
  top: '40px',
  right: '0px',
})
