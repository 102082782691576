import { useAtom } from 'jotai'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'
import english from '../../shared/translations/en.json'
import japanese from '../../shared/translations/ja.json'
import Cookies from 'js-cookie'
import { formatDistanceToNow } from 'date-fns'
import { ja, enUS } from 'date-fns/locale'

type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}`
    }[keyof T]
  : never
export type LanguageKey = Paths<typeof english>

type Language = {
  code: 'en' | 'ja'
  countryCode: string
  englishName: string
  name: string
}
export const SUPPORTED_LANGUAGES: Language[] = [
  {
    code: 'en' as const,
    countryCode: 'gb',
    englishName: 'English',
    name: 'English',
  },
  {
    code: 'ja' as const,
    countryCode: 'jp',
    englishName: 'Japanese',
    name: '日本語',
  },
]

const domainWithoutSpecial =
  '.' + document.location.host.replace('special.', '').replace(/:3000/, '')
const cookieStorage = createJSONStorage<Language['code']>(() => {
  return {
    getItem: (key) => Cookies.get(key) as Language['code'],
    setItem: (key, value) => {
      Cookies.set(key, value, {
        domain: domainWithoutSpecial,
      })
    },
    removeItem: (key) =>
      Cookies.remove(key, {
        domain: domainWithoutSpecial,
      }),
  }
})

const languageAtom = atomWithStorage(
  'lang',
  SUPPORTED_LANGUAGES[0]['code'],
  cookieStorage,
  {
    getOnInit: true,
  }
)

export default function useLanguage() {
  const [languageCode, setLanguageCode] = useAtom(languageAtom)
  const translation = languageCode === 'en' ? english : japanese

  function t(key: LanguageKey, replacements?: Record<string, string>): string {
    let result = replaceObjectInString(
      getNestedTranslation(key, translation),
      replacements
    )

    if (!result) {
      console.error(`Translation not found for key '${key}'.`)
      result = `%%% ${key} %%%`
    }
    return result
  }

  function getNestedTranslation(
    key: LanguageKey,
    translation: typeof english
  ): string {
    if (!translation) return ''
    const keys = key.split('.')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return keys.reduce((obj: any, key: string) => obj?.[key], translation)
  }

  function formatCurrency(
    value: number,
    displayCurrency = true,
    locale = languageCode
  ) {
    const currency = locale === 'ja' ? 'JPY' : 'USD'

    const options: Intl.NumberFormatOptions = {
      style: displayCurrency ? 'currency' : 'decimal',
      currency: displayCurrency ? currency : undefined,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }

    return new Intl.NumberFormat(locale, options).format(value)
  }

  function replaceObjectInString(
    text: string,
    replacements?: Record<string, string>
  ) {
    if (!replacements) return text
    // eslint-disable-next-line sonarjs/slow-regex
    return text.replace(/{{([^}]+)}}/g, (match, p1) => {
      return replacements[p1]
    })
  }

  function formatDate(date: Date) {
    return new Intl.DateTimeFormat(languageCode).format(date)
  }

  function formatRelativeDate(date: Date, languageCode: string = 'en') {
    const locale = languageCode === 'ja' ? ja : enUS

    return formatDistanceToNow(new Date(date), { addSuffix: true, locale })
  }

  return {
    languageCode,
    setLanguageCode,
    t,
    formatDate,
    formatCurrency,
    formatRelativeDate,
  }
}
