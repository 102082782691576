/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
} from '../models/index';

export interface AssetAssetIdThumbnailPutRequest {
    assetId: string;
    thumbnailUrl?: string;
}

/**
 * 
 */
export class AssetApi extends runtime.BaseAPI {

    /**
     * Updates the thumbnail of a specified video.
     */
    async assetAssetIdThumbnailPutRaw(requestParameters: AssetAssetIdThumbnailPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.assetId === null || requestParameters.assetId === undefined) {
            throw new runtime.RequiredError('assetId','Required parameter requestParameters.assetId was null or undefined when calling assetAssetIdThumbnailPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.thumbnailUrl !== undefined) {
            queryParameters['thumbnailUrl'] = requestParameters.thumbnailUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/asset/{assetId}/thumbnail`.replace(`{${"assetId"}}`, encodeURIComponent(String(requestParameters.assetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the thumbnail of a specified video.
     */
    async assetAssetIdThumbnailPut(requestParameters: AssetAssetIdThumbnailPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assetAssetIdThumbnailPutRaw(requestParameters, initOverrides);
    }

}
