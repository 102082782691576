/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth0ContextInterface } from '@auth0/auth0-react'

export const tokenRefreshMiddleware = (
  getAccessTokenSilently: () => Promise<string>,
  setToken: (token: string) => void,
  isAuthenticated: boolean,
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect']
) => {
  return {
    pre: async (context: any) => {
      if (isAuthenticated) {
        try {
          const newToken = await getAccessTokenSilently()
          setToken(newToken)

          context.init.headers = {
            ...context.init.headers,
            Authorization: `Bearer ${newToken}`,
          }
        } catch (error) {
          await handleAuthError(error, loginWithRedirect)
        }
      }
      return context
    },
  }
}

const handleAuthError = async (
  error: unknown,
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect']
): Promise<void> => {
  if (isAuthError(error)) {
    const errorCode = error.error

    const redirectToLoginErrorCodes = [
      'login_required',
      'missing_refresh_token',
      'invalid_grant',
    ]

    if (redirectToLoginErrorCodes.includes(errorCode)) {
      console.error(
        'Login required. Redirecting to Auth0 login page.',
        errorCode
      )
      await loginWithRedirect()
    } else {
      logTokenFetchError(errorCode)
    }
  } else {
    logUnexpectedError(error)
  }
}

const isAuthError = (error: unknown): error is { error: string } => {
  return typeof error === 'object' && error !== null && 'error' in error
}

const logTokenFetchError = (errorCode: string): never => {
  console.error('Error fetching access token:', errorCode)
  throw new Error('Unable to fetch access token.')
}

const logUnexpectedError = (error: unknown): never => {
  console.error('Unexpected error format:', error)
  throw new Error('Unable to fetch access token.')
}
