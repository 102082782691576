/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetType } from './AssetType';
import {
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './AssetType';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';
import type { MediaType } from './MediaType';
import {
    MediaTypeFromJSON,
    MediaTypeFromJSONTyped,
    MediaTypeToJSON,
} from './MediaType';

/**
 * 
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadImageResponse
     */
    id?: string;
    /**
     * 
     * @type {AssetType}
     * @memberof UploadImageResponse
     */
    assetType?: AssetType;
    /**
     * 
     * @type {MediaType}
     * @memberof UploadImageResponse
     */
    mediaType?: MediaType;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof UploadImageResponse
     */
    image?: ImageVariantsResponse;
}

/**
 * Check if a given object implements the UploadImageResponse interface.
 */
export function instanceOfUploadImageResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadImageResponseFromJSON(json: any): UploadImageResponse {
    return UploadImageResponseFromJSONTyped(json, false);
}

export function UploadImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadImageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'assetType': !exists(json, 'assetType') ? undefined : AssetTypeFromJSON(json['assetType']),
        'mediaType': !exists(json, 'mediaType') ? undefined : MediaTypeFromJSON(json['mediaType']),
        'image': !exists(json, 'image') ? undefined : ImageVariantsResponseFromJSON(json['image']),
    };
}

export function UploadImageResponseToJSON(value?: UploadImageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'assetType': AssetTypeToJSON(value.assetType),
        'mediaType': MediaTypeToJSON(value.mediaType),
        'image': ImageVariantsResponseToJSON(value.image),
    };
}

