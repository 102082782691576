/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPostAssetImageResponse } from './GetPostAssetImageResponse';
import {
    GetPostAssetImageResponseFromJSON,
    GetPostAssetImageResponseFromJSONTyped,
    GetPostAssetImageResponseToJSON,
} from './GetPostAssetImageResponse';
import type { GetPostAssetVideoResponse } from './GetPostAssetVideoResponse';
import {
    GetPostAssetVideoResponseFromJSON,
    GetPostAssetVideoResponseFromJSONTyped,
    GetPostAssetVideoResponseToJSON,
} from './GetPostAssetVideoResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetPostResponse
 */
export interface GetPostResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPostResponse
     */
    author: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetPostResponse
     */
    authorProfilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {string}
     * @memberof GetPostResponse
     */
    postId: string;
    /**
     * 
     * @type {string}
     * @memberof GetPostResponse
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    isPublished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    isLocked?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetPostResponse
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetPostResponse
     */
    scheduledAt?: Date | null;
    /**
     * 
     * @type {Array<GetPostAssetImageResponse>}
     * @memberof GetPostResponse
     */
    images?: Array<GetPostAssetImageResponse> | null;
    /**
     * 
     * @type {Array<GetPostAssetVideoResponse>}
     * @memberof GetPostResponse
     */
    videos?: Array<GetPostAssetVideoResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof GetPostResponse
     */
    likeCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetPostResponse
     */
    isLikedByUser?: boolean;
}

/**
 * Check if a given object implements the GetPostResponse interface.
 */
export function instanceOfGetPostResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "postId" in value;
    isInstance = isInstance && "text" in value;

    return isInstance;
}

export function GetPostResponseFromJSON(json: any): GetPostResponse {
    return GetPostResponseFromJSONTyped(json, false);
}

export function GetPostResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPostResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': json['author'],
        'authorProfilePicture': !exists(json, 'authorProfilePicture') ? undefined : ImageVariantsResponseFromJSON(json['authorProfilePicture']),
        'postId': json['postId'],
        'text': json['text'],
        'isPublished': !exists(json, 'isPublished') ? undefined : json['isPublished'],
        'isLocked': !exists(json, 'isLocked') ? undefined : json['isLocked'],
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (json['publishedAt'] === null ? null : new Date(json['publishedAt'])),
        'scheduledAt': !exists(json, 'scheduledAt') ? undefined : (json['scheduledAt'] === null ? null : new Date(json['scheduledAt'])),
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(GetPostAssetImageResponseFromJSON)),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(GetPostAssetVideoResponseFromJSON)),
        'likeCount': !exists(json, 'likeCount') ? undefined : json['likeCount'],
        'isLikedByUser': !exists(json, 'isLikedByUser') ? undefined : json['isLikedByUser'],
    };
}

export function GetPostResponseToJSON(value?: GetPostResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': value.author,
        'authorProfilePicture': ImageVariantsResponseToJSON(value.authorProfilePicture),
        'postId': value.postId,
        'text': value.text,
        'isPublished': value.isPublished,
        'isLocked': value.isLocked,
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt === null ? null : value.publishedAt.toISOString()),
        'scheduledAt': value.scheduledAt === undefined ? undefined : (value.scheduledAt === null ? null : value.scheduledAt.toISOString()),
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(GetPostAssetImageResponseToJSON)),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(GetPostAssetVideoResponseToJSON)),
        'likeCount': value.likeCount,
        'isLikedByUser': value.isLikedByUser,
    };
}

