import { useEffect, useMemo } from 'react'
import { Transition, useAnimated } from './Transition'
import { styled } from '../../../stitches'
import { useLocation } from 'react-router-dom'
import { BsX } from 'react-icons/bs'
import { usePopup } from '../../stores/usePopup'

type ModalProps = {
  Component: React.FC
  open: boolean
  onClose: () => void
  title?: string
  className?: string
}

const MODAL_TRANSITION_MS = 300

export const Modal = (props: ModalProps) => {
  const { Component, open, onClose, title, className } = props
  const anim = useAnimated(open, MODAL_TRANSITION_MS)

  useEffect(() => {
    if (anim !== 'closed') {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [anim])

  const ModalContentMemo = useMemo(() => <Component />, [Component])

  return (
    <Transition
      anim={anim}
      as={ModalBackdrop}
      onBeforeOpening={{ state: 'out' }}
      onOpening={{ state: 'in' }}
      onOpened={{ state: 'in' }}
      onBeforeClosing={{ state: 'in' }}
      onClosing={{ state: 'out' }}
      onClosed={{ state: 'closed' }}
    >
      {anim !== 'closed' && (
        <ModalContainer
          className={className}
          fullscreen={{ '@initial': true, '@tablet': false }}
          onClick={(e) => e.stopPropagation()}
        >
          <ModalCloseButton type="button" onClick={onClose}>
            <BsX />
          </ModalCloseButton>
          <ModalHeader>
            <h1>{title}</h1>
          </ModalHeader>
          <ScrollableContainer>{ModalContentMemo}</ScrollableContainer>
        </ModalContainer>
      )}
    </Transition>
  )
}

type RoutableModalProps = Omit<ModalProps, 'open' | 'onClose'> & {
  route: (modalId: string) => string
}

export function RoutableModal({ route, ...modalProps }: RoutableModalProps) {
  const { close } = usePopup()
  const location = useLocation()
  const routeQuery = route('')

  const isOpen = location.search.includes(routeQuery)

  return <Modal {...modalProps} open={isOpen} onClose={close} />
}

const ModalCloseButton = styled('button', {
  zIndex: 70,
  color: '#5C5C5C',
  border: 0,
  cursor: 'pointer',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  position: 'absolute',
  top: '0',
  right: '0',
  padding: '0.6rem',
  '& svg': {
    width: '2rem',
    height: '2rem',
  },
  '&:hover': {
    color: '#D5D5D5',
  },
})

const ModalHeader = styled('div', {
  margin: '1rem 0 2rem 0',
  h1: {
    margin: 0,
    display: 'block',
    textAlign: 'center',
    fontWeight: '400',
    fontSize: '1.2rem',
  },
})

const ModalContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexFlow: 'column',
  zIndex: 121,
  padding: '1rem',
  background: '#1E1E1E93',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  flexGrow: 1,
  height: '100%',
  width: '100%',
  borderRadius: 0,
  maxHeight: '100%',
  flexBasis: 0,
  variants: {
    fullscreen: {
      false: {
        flexGrow: 0,
        height: 'initial',
        borderRadius: '0.8rem',
        minWidth: '30rem',
        maxHeight: '80vh',
        width: '50vw',
      },
    },
  },
})

const ScrollableContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexFlow: 'column',
  overflowY: 'auto',
  padding: '1rem',
})

const ModalBackdrop = styled('div', {
  zIndex: 121,
  backdropFilter: 'blur(20px)',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: '100vw',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.77)',
  transition: `opacity ${MODAL_TRANSITION_MS}ms ease`,
  opacity: 0,
  variants: {
    state: {
      in: {
        visibility: 'unset',
        opacity: 1,
      },
      out: {
        visibility: 'unset',
        opacity: 0,
      },
      closed: {
        visibility: 'hidden',
        opacity: 0,
      },
    },
  },
})
