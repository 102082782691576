/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantResponse } from './ImageVariantResponse';
import {
    ImageVariantResponseFromJSON,
    ImageVariantResponseFromJSONTyped,
    ImageVariantResponseToJSON,
} from './ImageVariantResponse';

/**
 * 
 * @export
 * @interface GetPostAssetImageResponse
 */
export interface GetPostAssetImageResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPostAssetImageResponse
     */
    source: string;
    /**
     * 
     * @type {Array<ImageVariantResponse>}
     * @memberof GetPostAssetImageResponse
     */
    variants: Array<ImageVariantResponse>;
    /**
     * 
     * @type {string}
     * @memberof GetPostAssetImageResponse
     */
    readonly srcset: string;
    /**
     * 
     * @type {number}
     * @memberof GetPostAssetImageResponse
     */
    order: number;
}

/**
 * Check if a given object implements the GetPostAssetImageResponse interface.
 */
export function instanceOfGetPostAssetImageResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "variants" in value;
    isInstance = isInstance && "srcset" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function GetPostAssetImageResponseFromJSON(json: any): GetPostAssetImageResponse {
    return GetPostAssetImageResponseFromJSONTyped(json, false);
}

export function GetPostAssetImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPostAssetImageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'variants': ((json['variants'] as Array<any>).map(ImageVariantResponseFromJSON)),
        'srcset': json['srcset'],
        'order': json['order'],
    };
}

export function GetPostAssetImageResponseToJSON(value?: GetPostAssetImageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'variants': ((value.variants as Array<any>).map(ImageVariantResponseToJSON)),
        'order': value.order,
    };
}

